<template>
  <div class="pro">
    <p
      style="color:#000000; font-size:20px; font-weight: bold; margin:20px 0px 20px 0px;"
    >产品介绍 -PRO系列</p>
    <img class="w-100" src="../../assets/img/home-4.jpg" style="width:750px" />
    <img class="w-100" src="../../assets/img/top-1.jpg" style="width:750px" />
    <img class="w-100" src="../../assets/img/top-2.jpg" style="width:750px" />
    <img class="w-100" src="../../assets/img/top-3.jpg" style="width:750px" />
    <img class="w-100" src="../../assets/img/top-4.jpg" style="width:750px" />
    <img class="w-100" src="../../assets/img/top-5.jpg" style="width:750px" />
    <img class="w-100" src="../../assets/img/top-6.jpg" style="width:750px" />
    <img class="w-100" src="../../assets/img/top-7.jpg" style="width:750px" />
    <img class="w-100" src="../../assets/img/top-8.jpg" style="width:750px" />
    <img class="w-100" src="../../assets/img/top-9.jpg" style="width:750px" />
    <img class="w-100" src="../../assets/img/top-10.jpg" style="width:750px" />
  </div>
</template>

<script>
export default {
  components: {}
}
</script>
